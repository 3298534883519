// Popup.js
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import AddNew from "./AddNewTab";
import InviteExistingContact from "./InviteExistingContact";
import { EXISTING_CONTACTS_MENU, POPUP_CONTENT } from "./constants";
import { CLINICIAN_DATA, FAMILY_DATA, INTERPRETER_DATA } from "./data";
import useFetch from "../../API/useFetch";
import SkeletonLoader from "../SkeletonLoader";
import API_ENDPOINTS from "../../API/endpoints";
import useTooltip from "../useToolTip";
import { TOOLTIP_POSITION } from "../useToolTip/constants";

export const Checkbox = ({ name, onChange, initialState }) => {
  const [checked, setChecked] = useState(initialState ? initialState : false);

  useEffect(() => {
    onChange && onChange(checked);
  }, [checked]);

  return (
    <div style={{ display: "flex", padding: "10px 0px" }}>
      <img
        src={checked ? "/assets/icn-checked.svg" : "/assets/icn-unchecked.svg"}
        height={20}
        width={20}
        style={{ cursor: "pointer" }}
        onClick={() => setChecked(!checked)}
      />
      <div className={styles.CheckBoxStyle}>{name}</div>
    </div>
  );
};

const Popup = ({ isOpen, onClose }) => {
  const [activeMenuTab, setActiveMenuTab] = useState(
    EXISTING_CONTACTS_MENU.FAMILY
  );
  const [popUpContent, setPopUpContent] = useState(
    POPUP_CONTENT.INVITE_EXISTING_CONTACT
  );
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState([]);
  const [selectedClinicians, setSelectedClinicians] = useState([]);
  const [selectedInterpreters, setSelectedInterpreters] = useState([]);
  const [toolTipText, setToolTipText] = useState("Copy");
  const [askToJoinDisabled, setAskToJoinDisabled] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    relation: "",
  });

  const [ToolTip, showToolTip, hideToolTip] = useTooltip();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    const allFieldsFilled = Object.keys(formData).every(
      (key) => formData[key] !== ""
    );
    setAskToJoinDisabled(!allFieldsFilled);
  }, [formData]);
  useEffect(() => {
    if (
      selectedFamilyMembers.length > 0 ||
      selectedClinicians.length > 0 ||
      selectedInterpreters.length > 0
    ) {
      setAskToJoinDisabled(false);
    } else {
      setAskToJoinDisabled(true);
    }
  }, [selectedFamilyMembers, selectedClinicians, selectedInterpreters]);
  const [fetchData, apiData, isLoading] = useFetch();
  const [submitData] = useFetch();

  useEffect(() => {
    if (isOpen) {
      fetchData(
        API_ENDPOINTS.BASE_URL + API_ENDPOINTS.INVITE_USERS_LIST,
        "GET",
        undefined
      );
    }
  }, [isOpen]);

  const [filteredFamily, setFilteredFamily] = useState();
  const [filteredPatientCareTeam, setFilteredPatientCareTeam] = useState();
  const [filteredClinicianDirectory, setFilteredClinicianDirectory] = useState(
    CLINICIAN_DATA?.clinicianDirectory
  );
  const [filteredInterpreters, setFilteredInterpreters] = useState();

  useEffect(() => {
    console.log(apiData);
    setFilteredFamily(apiData?.relatedPersonList);
    setFilteredPatientCareTeam(apiData?.careTeamDetails);
    setFilteredInterpreters(apiData?.interpreterList);
  }, [apiData]);

  const handleSearchInputChange = (e) => {
    setSearchKeyword(e.target.value);
  };
  useEffect(() => {
    if (activeMenuTab === EXISTING_CONTACTS_MENU.FAMILY) {
      const filteredResults = apiData.relatedPersonList?.filter(
        (member) =>
          member.displayName.toLowerCase().includes(searchKeyword.toLowerCase())
        //   || member.relation.toLowerCase().includes(searchKeyword.toLowerCase())
      );
      setFilteredFamily(filteredResults);
    } else if (activeMenuTab === EXISTING_CONTACTS_MENU.INTERPRETERS) {
      const filteredResults = apiData?.interpreterList?.filter(
        (member) =>
          member?.interpreterName
            .toLowerCase()
            .includes(searchKeyword.toLowerCase()) ||
          member?.interpreterLanguage
            .toLowerCase()
            .includes(searchKeyword.toLowerCase())
      );
      setFilteredInterpreters(filteredResults);
    } else {
      const filteredResultsCareTeam = apiData.careTeamDetails?.filter(
        (member) =>
          member.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          member.practitionerRole[0]?.display
            .toLowerCase()
            .includes(searchKeyword.toLowerCase())
      );
      const filteredResultsClinicalTeam =
        CLINICIAN_DATA.clinicianDirectory.filter(
          (member) =>
            member.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            member.designation
              .toLowerCase()
              .includes(searchKeyword.toLowerCase())
        );
      setFilteredPatientCareTeam(filteredResultsCareTeam);
      setFilteredClinicianDirectory(filteredResultsClinicalTeam);
    }
  }, [searchKeyword, activeMenuTab]);

  const copyLink = () => {
    var currentUrl = window.location.href;
    currentUrl = currentUrl.replace(/&token=[^&]*/, "");
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        console.log("URL copied to clipboard:", currentUrl); //ToolTip
        showToolTip();
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
    setToolTipText("Copied to clipboard");
  };
  const handleAskToJoin = () => {
    if (
      selectedClinicians.length > 0 ||
      selectedFamilyMembers.length > 0 ||
      selectedInterpreters.length > 0
    ) {
      submitData(
        API_ENDPOINTS.BASE_URL + API_ENDPOINTS.INVITE_USER_TO_CALL,
        "POST",
        {
          relatedPersonList: selectedFamilyMembers,
          careTeamDetails: selectedClinicians,
          interpreterList: selectedInterpreters,
        }
      );
    } else {
      submitData(
        API_ENDPOINTS.BASE_URL + API_ENDPOINTS.INVITE_USER_TO_CALL,
        "POST",
        {
          callNewUserList: [
            {
              firstName: formData.name,
              lastName: formData.lastName,
              phoneNumber: formData.phoneNumber,
              emailId: formData.email,
              relationshipToPatient: formData.relation,
            },
          ],
        }
      );
    }
    onClose();
    setSelectedClinicians([]);
    setSelectedFamilyMembers([]);
    setSelectedInterpreters([]);
    setPopUpContent(POPUP_CONTENT.INVITE_EXISTING_CONTACT);
    setFormData({
      name: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      relation: "",
    });
  };

  return isOpen ? (
    <div
      className={styles.OverlayStyle}
      onClick={() => {
        onClose();
        setSelectedClinicians([]);
        setSelectedFamilyMembers([]);
        setSelectedInterpreters([]);
        setPopUpContent(POPUP_CONTENT.INVITE_EXISTING_CONTACT);
        setFormData({
          name: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          relation: "",
        });
      }}
    >
      <div
        className={styles.ContainerStyle}
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src="/assets/icn-cancel-circle.svg"
          onClick={() => {
            onClose();
            setSelectedClinicians([]);
            setSelectedFamilyMembers([]);
            setSelectedInterpreters([]);
            setPopUpContent(POPUP_CONTENT.INVITE_EXISTING_CONTACT);
            setFormData({
              name: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              relation: "",
            });
          }}
          style={{
            position: "absolute",
            right: "16px",
            top: "16px",
            cursor: "pointer",
          }}
        />
        {popUpContent === POPUP_CONTENT.ADD_NEW && (
          <div
            className={styles.BackButtonDiv}
            onClick={() => {
              setPopUpContent(POPUP_CONTENT.INVITE_EXISTING_CONTACT);
              setFormData({
                name: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                relation: "",
              });
            }}
          >
            <img src="/assets/icn-chevron-left.svg" />
            <p>Back</p>
          </div>
        )}
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <div className={styles.parent}>
            {popUpContent === POPUP_CONTENT.INVITE_EXISTING_CONTACT && (
              <InviteExistingContact
                activeMenuTab={activeMenuTab}
                setActiveMenuTab={setActiveMenuTab}
                searchKeyword={searchKeyword}
                handleSearchInputChange={handleSearchInputChange}
                filteredFamily={filteredFamily}
                selectedFamilyMembers={selectedFamilyMembers}
                setSelectedFamilyMembers={setSelectedFamilyMembers}
                filteredPatientCareTeam={filteredPatientCareTeam}
                filteredClinicianDirectory={filteredClinicianDirectory}
                selectedClinicians={selectedClinicians}
                setSelectedClinicians={setSelectedClinicians}
                filteredInterpreters={filteredInterpreters}
                selectedInterpreters={selectedInterpreters}
                setSelectedInterpreters={setSelectedInterpreters}
              />
            )}

            {popUpContent === POPUP_CONTENT.ADD_NEW && (
              <AddNew formData={formData} handleChange={handleChange} />
            )}
            <div className={styles.Footer}>
              <div className={styles.FooterLeftSection}>
                {popUpContent === POPUP_CONTENT.INVITE_EXISTING_CONTACT && (
                  <div
                    onClick={() => {
                      setPopUpContent(POPUP_CONTENT.ADD_NEW);
                      setSelectedInterpreters([]);
                      setSelectedClinicians([]);
                      setSelectedFamilyMembers([]);
                    }}
                    style={{ display: "flex", cursor: "pointer" }}
                  >
                    <img src="/assets/icn-add-purple.svg" />
                    <p>Add New</p>
                  </div>
                )}

                <div
                  style={{ display: "flex", cursor: "pointer" }}
                  onClick={copyLink}
                  onMouseEnter={() => showToolTip()}
                  onMouseLeave={() => {
                    hideToolTip();
                    setToolTipText("Copy");
                  }}
                >
                  <img src="/assets/icn-link.svg" />
                  <p>Share Invite Link</p>
                  <ToolTip
                    infoText={toolTipText}
                    position={TOOLTIP_POSITION.LEFT}
                    style={{ transform: "translateX(160px) translateY(12px)" }}
                  />
                </div>
              </div>
              <button
                onClick={handleAskToJoin}
                className={styles.buttonDisabled}
                style={
                  askToJoinDisabled
                    ? {
                        opacity: "0.2",
                        cursor: "not-allowed",
                        pointerEvents: "none",
                      }
                    : null
                }
              >
                Ask to join
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default Popup;
