import { useState, useCallback } from "react";
import { useCookies } from "react-cookie";

const useFetch = (defaultLoading = false, autoLogin = true) => {
  const [isLoading, setIsLoading] = useState(defaultLoading);
  const [isError, setIsError] = useState(false);
  const [apiData, setApiData] = useState({});
  const [cookies, setCookie] = useCookies(["token"]);
  
  const fetchData = useCallback(
    async (url, method, body, headers, callback, errorCallback) => {
      try {
        setIsLoading(true);
        const res = await fetch(url, {
          method: method || "GET",
          headers: {
            "Call-Token": cookies.token,
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify(body),
        });
        if (!res.ok) {
          setIsLoading(false);
          setIsError(true);
          if (errorCallback) errorCallback(res);
          
          return;
        }
        const data = await res.json();
        setApiData(data);
        setIsLoading(false);
        if (callback) callback(data);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setIsError(true);
        if (errorCallback) errorCallback(error);
      }
    },
    [cookies.access_token]
  );
  return [fetchData, apiData, isLoading, setIsLoading, isError];
};
export default useFetch;
