import React, { useRef, useCallback, useMemo, useEffect } from "react";
import styles from "./styles.module.scss";
import { TOOLTIP_POSITION } from "./constants";

function getTooltipArrowClass(position) {
  switch (position) {
    case TOOLTIP_POSITION.TOP:
      return styles.toolTipArrowTop;
    case TOOLTIP_POSITION.BOTTOM:
      return styles.toolTipArrowBottom;
    case TOOLTIP_POSITION.LEFT:
      return styles.toolTipArrowLeft;
    case TOOLTIP_POSITION.RIGHT:
      return styles.toolTipArrowRight;
    default:
      return styles.toolTipArrowTop;
  }
}

export const ToolTipWrapper = ({children, infoText, tooltipPosition, style}) => {
  const [Tooltip, showTooltip, hideTooltip] = useTooltip();

  
  

  return (
    <div>
      <div
        onMouseEnter={() => {
          console.log('onMouseEnter')
          showTooltip();
        }}
        
        onMouseLeave={() => {
          console.log('onMouseLeave')
          hideTooltip();
        }}>
        {children}
      </div>
      {infoText && (
      <Tooltip
        infoText={infoText}
        position={tooltipPosition || TOOLTIP_POSITION.RIGHT}
        style={style}
      />
    )}
    </div>
  );
};


const useTooltip = () => {
  const tooltipRef = useRef();
  const toolTipAnimationTimeout = useRef();

  const showToolTip = useCallback(() => {
    if (!tooltipRef.current) return;
    tooltipRef.current.style.display = "inline-block";
    if (toolTipAnimationTimeout.current)
      clearTimeout(toolTipAnimationTimeout.current);
    // need to do this so the initial fade in animation plays correctly
    setTimeout(() => {
      if (tooltipRef.current) tooltipRef.current.style.opacity = "1";
    }, 0);
  }, []);

  const hideToolTip = useCallback(() => {
    if (!tooltipRef.current) return;
    tooltipRef.current.style.opacity = "0";
    //   let the animation play before setting display none
    toolTipAnimationTimeout.current = setTimeout(() => {
      tooltipRef.current.style.display = "none";
      // 500 milliseconds is the transition duration assigned in css
    }, 500);
  }, []);

  const ToolTip = useMemo(() => {
    return ({ infoText, position, style }) => {
      return (
        <div
          className={`${styles.tooltipInfoTextContainer} ${getTooltipArrowClass(
            position
          )}`}
          ref={tooltipRef}
          style={style}
        >
          {infoText}
        </div>
      );
    };
  });

  return [ToolTip, showToolTip, hideToolTip];
};

export default useTooltip;
