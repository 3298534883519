import { useEffect } from "react";
import Button from "../../Components/Buttons";
import Utilities from "../../Utils/utilities";
import styles from "./style.module.scss";

export default function Lobby({
  setAdmitAllowedLobbyMember,
  setDeclineDiscardedLobbyMember,
  askToJoinLobby,
  mutePeer,
  removePeer,
  isAdmin,
  handleOpenPopup,
  participantStates,
  isLocalAudioMuted,
  isLocalVideoMuted,
  lobbyActive,
  setLobbyActive,
  randomColor,
}) {
  useEffect(() => {
    console.log(participantStates);
  }, [participantStates]);

  return (
    <div
      className={`${styles.lobbyParentDiv} ${
        lobbyActive ? styles.lobbyParentDivShow : styles.lobbyParentDivHide
      }`}
    >
      <div
        style={{
          position: "absolute",
          right: "6px",
          top: "12px",
          cursor: "pointer",
        }}
        onClick={() => setLobbyActive(false)}
      >
        <img src={"/assets/icn-cancel-circle.svg"} />
      </div>
      <div>
        {askToJoinLobby && askToJoinLobby?.length > 0 && (
          <>
            <div className={styles.lobbyHeading}>
              <p>WAITING IN LOBBY</p>
            </div>
            <div>
              {askToJoinLobby.map((item, index) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px 16px 10px 16px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      className={styles.lobbyCircle}
                      style={{
                        background: `${randomColor}`,
                      }}
                    >
                      {Utilities.getInitials(item.displayName)}
                    </div>
                    <p style={{ marginLeft: "1rem", wordBreak: "break-all" }}>
                      {item.displayName}
                    </p>
                  </div>

                  <div style={{ display: "flex" }}>
                    <Button.IconButton
                      icon={"/assets/icn-trash.svg"}
                      onClickHandler={() =>
                        setDeclineDiscardedLobbyMember(item)
                      }
                      style={{
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #DADEE1",
                      }}
                    />
                    <Button.IconButton
                      text={"Admit"}
                      icon={null}
                      onClickHandler={() => setAdmitAllowedLobbyMember(item)}
                      style={{
                        backgroundColor: "#F0E6FF",
                        color: "#8549FF",
                        paddingLeft: "0.5rem",
                        paddingRight: "1rem",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        <div className={styles.lobbyHeading}>
          <p>IN THE MEETING</p>
          <div
            style={{
              marginRight: "2rem",
              marginTop: "0.5rem",
              cursor: "pointer",
            }}
            onClick={() => setLobbyActive(false)}
          ></div>
        </div>
        {Object.keys(participantStates).map((peerId) => (
          <div
            key={peerId}
            style={{
              padding: "10px 16px 10px 16px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                className={styles.lobbyCircle}
                style={{
                  background: `${randomColor}`,
                }}
              >
                {Utilities.getInitials(participantStates[peerId]?.displayName)}
              </div>
              <p style={{ marginLeft: "1rem", wordBreak: "break-all" }}>
                {" "}
                {participantStates[peerId].displayName}
              </p>
            </div>

            <div style={{ display: "flex" }}>
              <Button.IconButton
                icon={
                  participantStates[peerId]?.audioMuted
                    ? "/assets/icn-microphone-off-red.svg"
                    : "/assets/icn-microphone-on.svg"
                }
                imageHeight={24}
                imageWidth={24}
                onClickHandler={() => {
                  if (isAdmin) {
                    mutePeer(
                      peerId,
                      participantStates[peerId].isPatient,
                      !participantStates[peerId].audioMuted,
                      participantStates[peerId].videoMuted,
                      participantStates[peerId].isScreenBeingShared
                    );
                  }
                }}
                style={{
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #DADEE1",
                }}
              />
              <Button.IconButton
                icon={
                  participantStates[peerId]?.videoMuted
                    ? "/assets/icn-video-off-red.svg"
                    : "/assets/camera-icon-on.svg"
                }
                imageHeight={24}
                imageWidth={24}
                onClickHandler={() => {
                  if (isAdmin) {
                    mutePeer(
                      peerId,
                      participantStates[peerId].isPatient,
                      participantStates[peerId].audioMuted,
                      !participantStates[peerId].videoMuted,
                      participantStates[peerId].isScreenBeingShared
                    );
                  }
                }}
                style={{
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #DADEE1",
                }}
              />
              {isAdmin ? (
                <Button.IconButton
                  text={"X"}
                  imageHeight={24}
                  imageWidth={24}
                  onClickHandler={() => removePeer(peerId)}
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #DADEE1",
                  }}
                />
              ) : null}
            </div>
          </div>
        ))}
        <div
          style={{
            padding: "10px 16px 10px 16px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              className={styles.lobbyCircle}
              style={{
                background: "#5FC88E",
              }}
            >
              YU
            </div>
            <p style={{ marginLeft: "1rem" }}>(YOU)</p>
          </div>

          <div style={{ display: "flex" }}>
            <Button.IconButton
              icon={
                isLocalAudioMuted
                  ? "/assets/icn-microphone-off-red.svg"
                  : "/assets/icn-microphone-on.svg"
              }
              imageHeight={24}
              imageWidth={24}
              style={{
                backgroundColor: "#FFFFFF",
                border: "1px solid #DADEE1",
              }}
            />
            <Button.IconButton
              icon={
                isLocalVideoMuted
                  ? "/assets/icn-video-off-red.svg"
                  : "/assets/camera-icon-on.svg"
              }
              imageHeight={24}
              imageWidth={24}
              style={{
                backgroundColor: "#FFFFFF",
                border: "1px solid #DADEE1",
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          padding: "10px 16px 10px 16px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button.IconButton
          icon={"/assets/icn-invite.svg"}
          text={"Invite Others"}
          onClickHandler={handleOpenPopup}
          style={{
            backgroundColor: "#8549FF",
            color: "#FFFFFF",
            width: "100%",
            padding: "12px",
          }}
        />
      </div>
    </div>
  );
}
