import styles from "./style.module.scss";

export default function CallEndedScreen({ totalCallTime }) {
  const convertedTime = totalCallTime.replace(":", "m ") + "s";
  const handleRefreshClick = () => {
    window.location.reload();
  };
  
  return (
    <div>
      <div className={styles.wrapperDiv}>
        <img className={styles.logoImg} src="/logo-2.png" />

        <img
          className={styles.profileImg}
          src="/assets/icn-you-left.png"
        />

        <div className={styles.callEndedTitle}>
        You’ve left the session
          <p className={styles.timeParagraph}>Call minutes : {convertedTime}</p>
        </div>

        <div className={styles.callEndedSubtitle}>
          You can close this window
        </div>
        <div
          className={styles.callEndedSubtitle}
          style={{ fontSize: "16px", marginTop: "7rem", display: "flex" }}
        >
          Left accidentally?{" "}
          <span
            style={{
              color: "#8549FF",
              marginLeft: "0.5rem",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={handleRefreshClick}
          >
            Rejoin Session{" "}
            <div style={{ position: "relative", bottom: "2px" }}>
              <img src="/assets/icn-arrow-right.svg" />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
}
