import { useEffect, useRef, useState } from "react";
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";

function ZoomApp({ token, setToken }) {

    const searchParams = new URLSearchParams(document.location.search);

    const userName = 'User' + Math.floor(Math.random()*(10**10));

    const [callEnded, setCallEnded] = useState(false);

    useEffect(() => {
        if(!token) {
            console.log('No token found');
            return;
        }

        var sessionContainer = document.getElementById('sessionContainer')
        const hospitalId = searchParams.get("hospitalId")
        const callId = searchParams.get("callId")

        var config = {
            videoSDKJWT: token,
            sessionName: hospitalId + "-" + callId,
            userName: userName,
            features: ['preview', 'video', 'audio', 'share', 'chat', 'users', 'settings'],
            options: { init: {}, audio: {}, video: {}, share: {}},
            virtualBackground: {
                allowVirtualBackground: false
            }
        }

        uitoolkit.joinSession(sessionContainer, config)
        uitoolkit.onSessionClosed(sessionClosed)
    }, [token]);

    const sessionClosed = () => {
        console.log("session closed");
        setCallEnded(true);
    };
      
    if(callEnded) {
        return (
            <div>
                <h1>Call Ended</h1>
                <div>You can close this window now</div>
            </div>
        )
    } else {
        return (
            <div style={{maxWidth: '70%', margin: 'auto'}} id='sessionContainer'></div>
        )
    }
}

export default ZoomApp;