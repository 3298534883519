import React from "react";
import styles from "./style.module.scss";

const Cross = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M5.99847 4.52714L2.63367 1.16247C2.22833 0.75574 1.57034 0.75574 1.16222 1.16247C0.755496 1.5692 0.755496 2.22718 1.16222 2.63391L4.52703 5.99858L1.165 9.36228C0.758273 9.769 0.758273 10.427 1.165 10.8337C1.36767 11.0378 1.6342 11.1391 1.90072 11.1391C2.16725 11.1391 2.43377 11.0378 2.63644 10.8337L5.99847 7.47002L9.36467 10.8377C9.56734 11.0418 9.83387 11.1431 10.1004 11.1431C10.3655 11.1431 10.6334 11.0418 10.8361 10.8377C11.2428 10.431 11.2428 9.77303 10.8361 9.3663L7.46991 5.99858L10.8333 2.63682C11.2401 2.2301 11.2401 1.57211 10.8333 1.16538C10.4266 0.758655 9.76862 0.758655 9.36189 1.16538L5.99847 4.52714Z"
        fill={color}
      />
    </svg>
  );
};
const Exclamation = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 2C5.71667 2 5.47933 2.09567 5.288 2.287C5.096 2.479 5 2.71667 5 3V6C5 6.28333 5.096 6.52067 5.288 6.712C5.47933 6.904 5.71667 7 6 7C6.28333 7 6.521 6.904 6.713 6.712C6.90433 6.52067 7 6.28333 7 6V3C7 2.71667 6.90433 2.479 6.713 2.287C6.521 2.09567 6.28333 2 6 2ZM6 10C6.28333 10 6.521 9.904 6.713 9.712C6.90433 9.52067 7 9.28333 7 9C7 8.71667 6.90433 8.47933 6.713 8.288C6.521 8.096 6.28333 8 6 8C5.71667 8 5.47933 8.096 5.288 8.288C5.096 8.47933 5 8.71667 5 9C5 9.28333 5.096 9.52067 5.288 9.712C5.47933 9.904 5.71667 10 6 10Z"
        fill={color}
      />
    </svg>
  );
};

const ErrorPopUp = ({ isOpen, handleClose }) => {
  const popupContainerStyle = {
    display: isOpen ? "flex" : "none",
  };

  return (
    <div className={styles.popupContainer} style={popupContainerStyle}>
      <div className={styles.popupStyle}>
        <div style={{position : 'absolute' , top:'12px', right:'12px' , cursor:'pointer'}} onClick={handleClose}>
          <Cross width={30} height={20} color={"black"} />
        </div>
        <div className={styles.RedCircle}>

          <Exclamation width={90} height={89} color={"white"} />
        </div>

        <h2>Please request to join</h2>
        <p>
          You are not permitted to join the call or your link has expired.
        </p>
      </div>
    </div>
  );
};

export default ErrorPopUp;
