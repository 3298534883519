import { Checkbox } from "..";
import { EXISTING_CONTACTS_MENU } from "../constants";
import styles from "./style.module.scss";
export default function InviteExistingContact({
  activeMenuTab,
  setActiveMenuTab,
  searchKeyword,
  handleSearchInputChange,
  filteredFamily,
  selectedFamilyMembers,
  setSelectedFamilyMembers,
  filteredPatientCareTeam,
  filteredClinicianDirectory,
  selectedClinicians,
  setSelectedClinicians,
  filteredInterpreters,
  selectedInterpreters,
  setSelectedInterpreters,
}) {
  return (
    <>
      <div className={styles.HeaderTitle}>
        Ask others to join this session
        <p className={styles.HeaderSubTitle}>
          {" "}
          Select from the hospital directory or add a new contact
        </p>
      </div>
      <div className={styles.MenuTopDiv}>
        <div
          className={styles.MenuItem}
          style={{
            background: activeMenuTab === EXISTING_CONTACTS_MENU.FAMILY ? "#F0E6FF" : "#EDEDED",
          }}
          onClick={() => setActiveMenuTab(EXISTING_CONTACTS_MENU.FAMILY)}
        >
          Family
        </div>
        <div
          className={styles.MenuItem}
          style={{
            background: activeMenuTab === EXISTING_CONTACTS_MENU.CLINICIANS ? "#F0E6FF" : "#EDEDED",
          }}
          onClick={() => setActiveMenuTab(EXISTING_CONTACTS_MENU.CLINICIANS)}
        >
          Clinicians
        </div>
        <div
          className={styles.MenuItem}
          style={{
            background:
              activeMenuTab === EXISTING_CONTACTS_MENU.INTERPRETERS ? "#F0E6FF" : "#EDEDED",
          }}
          onClick={() => setActiveMenuTab(EXISTING_CONTACTS_MENU.INTERPRETERS)}
        >
          Interpreters
        </div>
      </div>
      <div className={styles.SearchBarSection}>
        <div className={styles.SearchBarDiv}>
          <img
            src="/assets/icn-search.svg"
            height={24}
            width={24}
            style={{ cursor: "pointer" }}
          />
          <input
            type="text"
            value={searchKeyword}
            onChange={handleSearchInputChange}
            placeholder="Search"
            className={styles.SearchBarInput}
          />
        </div>
      </div>
      <div className={styles.CheckBoxListSection}>
        {activeMenuTab === EXISTING_CONTACTS_MENU.FAMILY && (
          <>
            {(filteredFamily && filteredFamily.length > 0) ? (
              filteredFamily.map((member, index) => (
                <Checkbox
                  initialState={selectedFamilyMembers.includes(member)}
                  key={index}
                  onChange={(isChecked) => {
                    if (isChecked) {
                      !selectedFamilyMembers.includes(member) &&
                        setSelectedFamilyMembers([
                          ...selectedFamilyMembers,
                          member
                        ]);
                    } else {
                      let deletedArray = selectedFamilyMembers.filter(
                        (item) => item !== member
                      );
                      setSelectedFamilyMembers(deletedArray);
                    }
                  }}
                  name={`${member.displayName}`}
                />
              ))
            ) : (
              <p>No Results Found</p>
            )}
          </>
        )}
        {activeMenuTab === EXISTING_CONTACTS_MENU.CLINICIANS && (
          <>
            <div className={styles.CheckBoxListHeading}>Patient Care Team</div>
            {(filteredPatientCareTeam && filteredPatientCareTeam.length > 0) ? (
              filteredPatientCareTeam.map((member, index) => (
                <Checkbox
                  initialState={selectedClinicians.includes(member)}
                  key={index}
                  onChange={(isChecked) => {
                    if (isChecked) {
                      !selectedClinicians.includes(member) &&
                        setSelectedClinicians([
                          ...selectedClinicians,
                          member,
                        ]);
                    } else {
                      let deletedArray = selectedClinicians.filter(
                        (item) => item !== member
                      );
                      setSelectedClinicians(deletedArray);
                    }
                  }}
                  name={`${member.name} (${member.practitionerRole[0]?.display})`}
                />
              ))
            ) : (
              <p>No Results Found</p>
            )}
            <div className={styles.CheckBoxListHeading}>
              Clinician Directory
            </div>
            {filteredClinicianDirectory.length > 0 ? (
              filteredClinicianDirectory.map((member, index) => (
                <Checkbox
                  initialState={selectedClinicians.includes(member.name)}
                  key={index}
                  onChange={(isChecked) => {
                    if (isChecked) {
                      !selectedClinicians.includes(member.name) &&
                        setSelectedClinicians([
                          ...selectedClinicians,
                          member.name,
                        ]);
                    } else {
                      let deletedArray = selectedClinicians.filter(
                        (item) => item !== member.name
                      );
                      setSelectedClinicians(deletedArray);
                    }
                  }}
                  name={`${member.name} (${member.designation})`}
                />
              ))
            ) : (
              <p>No Results Found</p>
            )}
          </>
        )}
        {activeMenuTab === EXISTING_CONTACTS_MENU.INTERPRETERS && (
          <>
            <div className={styles.CheckBoxListHeading}>
              Interpreter Directory
            </div>
            {(filteredInterpreters && filteredInterpreters?.length  > 0) ? (
              filteredInterpreters.map((member, index) => (
                <Checkbox
                  initialState={selectedInterpreters.includes(member)}
                  key={index}
                  onChange={(isChecked) => {
                    if (isChecked) {
                      !selectedInterpreters.includes(member) &&
                        setSelectedInterpreters([
                          ...selectedInterpreters,
                          member,
                        ]);
                    } else {
                      let deletedArray = selectedInterpreters.filter(
                        (item) => item !== member
                      );
                      setSelectedInterpreters(deletedArray);
                    }
                  }}
                  name={`${member.interpreterName}(${member.interpreterLanguage})`}
                />
              ))
            ) : (
              <p>No Results Found</p>
            )}
          </>
        )}
      </div>
    </>
  );
}
