import { useEffect, useRef, useState } from "react";

import LocalVideoStream from "../../Components/LocalVideoStream";
import Header from "../../Components/HeaderComponent";
import styles from "./style.module.scss";
import ControlButton from "../../Components/ControlButtons";
import NamePlate from "../../Components/NamePlate";
import Button from "../../Components/Buttons";
import Popup from "../../Components/Popup";

export default function CheckpointScreen({
  _token,
  isLocalAudioMuted,
  setIsLocalAudioMuted,
  guestDisplayName,
  setGuestDisplayName,
  isLocalVideoMuted,
  selectedAudio,
  selectedVideo,
  setSelectedAudio,
  setSelectedVideo,
  setIsLocalVideoMuted,
  showSelectDevicePopUp,
  setShowSelectDevicePopUp,
  patientName,
  setHasAskedToJoin,
  setHasVideoCallJoined,
  joinedPeerListCount,
}) {
  var videoRefFromChild = null;
  const [isPopupOpen, setPopupOpen] = useState(false);

  const [askToJoinDisabled, setAskToJoinDisabled] = useState(true);

  const [askedToJoin, setAskedToJoin] = useState(false);

  useEffect(() => {
    guestDisplayName !== ""
      ? setAskToJoinDisabled(false)
      : setAskToJoinDisabled(true);
  }, [guestDisplayName]);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div>
        <Header imgSource={"/logo-2.png"} />
        <div className={styles.mainContainer}>
          <div className={styles.localVideoContainer}>
            <LocalVideoStream
              isLocalAudioMuted={isLocalAudioMuted}
              isLocalVideoMuted={isLocalVideoMuted}
              selectedAudio={selectedAudio}
              selectedVideo={selectedVideo}
              setSelectedAudio={setSelectedAudio}
              setSelectedVideo={setSelectedVideo}
              showSelectDevicePopUp={showSelectDevicePopUp}
              setShowSelectDevicePopUp={setShowSelectDevicePopUp}
              updateVideoRef={(videoRef) => {
                videoRefFromChild = videoRef;
              }}
            />
            <ControlButton
              withEndCallBttn={false}
              isLocalAudioMuted={isLocalAudioMuted}
              isLocalVideoMuted={isLocalVideoMuted}
              setShowSelectDevicePopUp={setShowSelectDevicePopUp}
              setIsLocalAudioMuted={setIsLocalAudioMuted}
              setIsLocalVideoMuted={setIsLocalVideoMuted}
            />
          </div>
          <div className={styles.localDetailsContainer}>
            <h2>Room 306-A</h2>
            {joinedPeerListCount && joinedPeerListCount > 0 ? (
              <h5>
                {joinedPeerListCount}{" "}
                {joinedPeerListCount == 1 ? (
                  <>other person has</>
                ) : (
                  <>others have</>
                )}{" "}
                joined the call
              </h5>
            ) : null}
            {!_token && (
              <div className={styles.InputLabelBoxContainer}>
                <div style={{ margin: "0", padding: "0", display: "flex" }}>
                  {" "}
                  Name{" "}
                  <span style={{ display: "inline", color: "red" }}>*</span>
                </div>
                <div className={styles.InputBoxDiv}>
                  <input
                    name="name"
                    value={guestDisplayName}
                    onChange={(e) => setGuestDisplayName(e.target.value)}
                    required
                    type="text"
                    className={styles.Input}
                    placeholder="Enter name"
                  />
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                paddingLeft: "3rem",
              }}
            >
              {_token ? (
                <>
                  <Button.IconButton
                    icon={"/assets/icn-video.svg"}
                    style={{
                      marginTop: 32,

                      flexDirection: "row",
                      backgroundColor: "#8549FF",
                      color: "white",
                      padding: "12px 48px",
                    }}
                    onClickHandler={() => {
                      setHasVideoCallJoined(true);
                    }}
                    text="Join Session"
                  />
                  <Button.IconButton
                    icon={"/assets/icn-add-contact.svg"}
                    style={{
                      marginTop: 32,
                      flexDirection: "row",
                      backgroundColor: "#F0E6FF",
                      color: "#8549FF",
                      padding: "12px 48px",
                    }}
                    onClickHandler={handleOpenPopup}
                    text="Invite Others"
                  />
                </>
              ) : !askedToJoin ? (
                <Button.IconButton
                  icon={"/assets/icn-video.svg"}
                  style={
                    askToJoinDisabled
                      ? {
                          opacity: "0.2",
                          cursor: "not-allowed",
                          pointerEvents: "none",
                          marginTop: 32,
                          marginLeft: 20,

                          flexDirection: "row",
                          backgroundColor: "#8549FF",
                          color: "white",
                          padding: "17px 5rem",
                        }
                      : {
                          marginTop: 32,
                          marginLeft: 20,

                          flexDirection: "row",
                          backgroundColor: "#8549FF",
                          color: "white",
                          padding: "17px 5rem",
                        }
                  }
                  onClickHandler={() => {
                    setAskedToJoin(true);
                    setHasAskedToJoin(true);
                    setAskToJoinDisabled(true);
                  }}
                  text={"Ask To Join"}
                />
              ) : (
                <h5>
                  <img
                    src="/assets/loader.svg"
                    className={styles.loaderImage}
                  />
                  Waiting for Someone to Admit You To The Call
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
      <Popup isOpen={isPopupOpen} onClose={handleClosePopup} />
    </>
  );
}
