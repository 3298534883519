import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./style.module.scss";
// import SVGIcons from "../SVGIcons";

/**
 *
 * @prop {array} values :  [string, ...] options of the dropdown
 * @prop {array} valuesWithIcons : [{ value : <Component> }, ...] options with icons for dropdown
 * (only requires one of the above)
 */

const DropDownWithLabel = ({
  labels,
  values,
  valuesWithIcons,
  placeholder,
  onChange,
  style,
  dropdownValuesStyle,
  initialValue,
  disabled = false
}) => {
  const [selectedValue, setSelectedValue] = useState();
  const [selectedLabel, setSelectedLabel] = useState();
  const [dropDownActive, setDropDownActive] = useState(false);
  const [scrollBy, setScrollBy] = useState(0);
  const wrapperRef = useRef();
  const optionsRef = useRef();

  const handleMouseUp = useCallback(event => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      setDropDownActive(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseUp);
    return () => {
      window.removeEventListener("mousedown", handleMouseUp);
    };
  }, [handleMouseUp]);

  const options = values || Object.keys(valuesWithIcons || {});

  useEffect(() => {
    if (initialValue && options.includes(initialValue))
      setSelectedValue(initialValue);
  }, [initialValue, setSelectedValue]);

  function dropDownclicked() {
    if (!disabled) {
      setDropDownActive(true);
    }
  }

  const scrollDown = useCallback(() => {
    if (optionsRef && optionsRef.current) {
      optionsRef.current.scrollTop = scrollBy;
    }
  }, [optionsRef, scrollBy])

  useEffect(() => {
    if (dropDownActive) {
      scrollDown();
    }
  }, [dropDownActive])

  useEffect(() => {
    if (options.indexOf(selectedValue) !== -1) {
      setScrollBy(options.indexOf(selectedValue) * 40);
      setSelectedLabel(labels[options.indexOf(selectedValue)]);
    }
  }, [selectedValue])

  return (
    <div ref={wrapperRef} className={styles.drownDownContainer} style={style}>
      <div
        className={`${styles.titleContainer} ${dropDownActive && styles.dropDownActive
          }`}
        onClick={dropDownclicked}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 12,
          }}
        >
          {valuesWithIcons && valuesWithIcons[selectedValue]}
          {(!disabled) &&
            <p className={`${selectedValue !== placeholder ? styles.titleSelected : styles.title}`}>{selectedLabel}</p>
          }
          {(disabled) &&
            <p className={styles.title}>{selectedLabel}</p>
          }

        </div>
        
      </div>
      {dropDownActive && (
        <div className={styles.dropDownValuesContainer} style={dropdownValuesStyle} ref={optionsRef}>
          {options.map((value, index) => {
            return (
              <div
                className={`${styles.dropDownItem} ${value === selectedValue && styles.dropDownItemSelected
                  }`}
                key={value}
                onClick={() => {
                  setSelectedValue(value);
                  setSelectedLabel(labels[index] ?? options[index]);
                  onChange(value);
                  setDropDownActive(false);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: 12,
                  }}
                >
                  {valuesWithIcons && valuesWithIcons[value]}
                  <p className={styles.dropDownItemTitle}>{labels[index] ?? options[index]}</p>
                </div>

                
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DropDownWithLabel;