import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import ErrorPopUp from "./Components/ErrorPopUp";
import API_ENDPOINTS from "./API/endpoints";
import styles from "./UsbCameraMonitoringView.module.scss";
import * as jose from 'jose'

var mySid = null;

var socketEndpoint = API_ENDPOINTS.SOCKET_URL;
console.log("socketEndpoint", socketEndpoint);

var socket = io(socketEndpoint, {
  transports: ["websocket"],
});

var socketInitialized = false;
var peerList = {};
let localStream = null;

let participantStreams = new Map();

function UsbCameraMonitoringView({ token, setToken }) {
  const [monitoringRoomTokenDict, setMonitoringRoomTokenDict] = useState({});

  const [hasMonitoringEnded, setHasMonitoringEnded] = useState(false);
  const searchParams = new URLSearchParams(document.location.search);
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);

  const [selectedAudio, setSelectedAudio] = useState("");
  const [selectedVideo, setSelectedVideo] = useState("");

  var _hospitalId = searchParams.get("hospitalId");
  var _usbCameraDeviceId = searchParams.get("usbCameraDeviceId");
  var _usbMonitoringRoomId = searchParams.get("usbMonitoringRoomId");
  var _isMonitoringUser = searchParams.get("isMonitoringUser");

  function addParticipantStream(peerId, stream) {
    participantStreams.set(peerId, stream);
  }
  function removeParticipantStream(peerId) {
    participantStreams.delete(peerId);
  }

  async function getJwtTokenForDevice() {
    const jwtTokenData = {
        "hospital_id": _hospitalId,
        "usb_camera_device_id": _usbCameraDeviceId,
    }

    const secret = new TextEncoder().encode(
        'wekjni43jn352jkn4lkjnrgkjfd', // incorrect dummy secret - replace with actual secret later
    )
    const alg = 'HS256'
    
    const jwtToken = await new jose.SignJWT(jwtTokenData)
        .setProtectedHeader({ alg })
        .setIssuedAt()
        .setExpirationTime('2h')
        .sign(secret)
      
    console.log("jwtToken", jwtToken);
    return jwtToken;
  }

  function videoMonitoringEndedSelf() {

    Object.values(peerList).forEach((peerConnection) => {
      if (peerConnection) {
        console.log("Closing peer connection.");
        peerConnection.close();
      }
    });
    peerList = {};
    if (localStream) {
      localStream.getTracks().forEach((track) => {
        track.stop();
      });
      localStream = null;
    }
    if (socket.connected) {
      socket.disconnect();
    }
    setHasMonitoringEnded(true);
  }

  const openErrorPopup = () => {
    setIsErrorPopupOpen(true);
  };

  const closePopup = () => {
    setIsErrorPopupOpen(false);
  };
  function isChrome() {
    return /chrome|chromium|crios/i.test(navigator.userAgent);
  }

  var PC_CONFIG = {
    iceServers: [
      isChrome()
        ? { url: "stun:dev-stun.lana.health:3478" }
        : { urls: "stun:dev-stun.lana.health:3478" },
      {
        urls: "turn:dev-turn.lana.health:3478",
        username: "lana-turn-server",
        credential: "somepassword",
      },
    ],
  };

  function sendViaServer(data) {
    console.log("Sending via server: ", JSON.stringify(data));
    socket.emit("data", JSON.stringify(data));
  }

  useEffect(() => {
    if (socketInitialized) {
      return;
    }
    socketInitialized = true;

    console.log("socket connected....");

    socket.on("connect", () => {
        if(_isMonitoringUser) {
            var data = {
                hospitalId: _hospitalId,
                usbCameraDeviceId: _usbCameraDeviceId,
                usbMonitoringRoomId: _usbMonitoringRoomId,
                isMonitoringUser: true,
                jwtToken: token,
            };

            if (token) {
                console.log("emitting join-usb-monitoring-room");
                socket.emit("join-usb-monitoring-room", data);
            } else {
                console.log("token is empty");
            }
        } else {
            getJwtTokenForDevice()
                .then((jwtToken) => {
                    var data = {
                        hospitalId: _hospitalId,
                        usbCameraDeviceId: _usbCameraDeviceId,
                        jwtToken: jwtToken
                    };
                    
                    socket.emit("join-usb-camera-room", data);
                    console.log("emitting join-usb-camera-room");
                });
        }
    });

    socket.on("request-join-usb-monitoring-room", (data) => {
        console.log("request-join-usb-monitoring-room", data);
        
        if (data["jwtToken"] && data["usbMonitoringRoomId"]) {

            setMonitoringRoomTokenDict((prevDict) => ({
                ...prevDict,
                [data["usbMonitoringRoomId"]]: data["jwtToken"],
            }));

            var usbMonitoringRoomData = {
                hospitalId: _hospitalId,
                usbCameraDeviceId: _usbCameraDeviceId,
                usbMonitoringRoomId: data["usbMonitoringRoomId"],
                isMonitoringUser: false,
                jwtToken: data["jwtToken"],
            };
            console.log("emitting join-usb-monitoring-room");
            socket.emit("join-usb-monitoring-room", usbMonitoringRoomData);
        }
    });

    socket.on("usb-monitoring-user-connect", (data) => {
      console.log("usb-monitoring-user-connect ", data);

      let peerId = data["sid"];
      peerList[peerId] = undefined; // add new user to user list

      console.log("Updated peer list", peerList);
      console.log("peerList: ", peerList);
      addDiv(peerId);
    });

    socket.on("user-disconnect", (data) => {
      console.log("user-disconnect ", data);
      let peerId = data["sid"];
      if (peerId === mySid) {
        videoMonitoringEndedSelf();
        setToken("");
      } else {
        closeConnection(peerId);
        removeParticipantStream(peerId);
        console.log("Participant Stremas Map", participantStreams);
        removeVideoElement(peerId);
      }
    });

    socket.on("usb-monitoring-user-list", (data) => {
      console.log("user list recvd ", data);
      let _mySid = data["my_sid"];
      mySid = _mySid;

      if ("usersInRoom" in data) {
        // not the first to connect to room, existing user list recieved
        let recvd_list = data["usersInRoom"];
        var otherUsersFound = false;
        // add existing users to user list
        for (let peerId in recvd_list) {
          console.log("Comparing " + peerId + " " + _mySid);
          if (peerId !== _mySid) {
            console.log("Adding peerId to peerList", peerId);
            peerList[peerId] = undefined;
            otherUsersFound = true;
          }
          // todo: add video frame
        }
        console.log("Updated peer list2", peerList);
        if (otherUsersFound) {
          console.log("otherUsersFound = true for peerList: ", peerList);
        }
        start_webrtc();
      }
    });

    socket.on("data", (msg) => {
      console.log("socket.on(data) : ", msg);
      switch (msg.type) {
        case "SessionDescription":
          switch (msg.payload.type) {
            case "offer":
              console.log("handelling offer");
              handleOfferMsg(msg);
              break;
            case "answer":
              console.log("handelling answer");
              handleAnswerMsg(msg);
              break;
          }
          break;
        case "IceCandidate":
          console.log("handelling IceCandidate");
          handleNewICECandidateMsg(msg);
          break;
      }
    });
  }, []);


  useEffect(() => {
    function switchMediaTracks() {
      const currentStream = localStream;

      if (!currentStream) return;

      navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: selectedAudio ? { exact: selectedAudio } : undefined,
          },
          video: {
                deviceId: selectedVideo ? { exact: selectedVideo } : undefined,
                width: 1080,
                height: 720,
            }
        })
        .then((newStream) => {
          const oldAudioTrack = currentStream.getAudioTracks()[0];
          const newAudioTrack = newStream.getAudioTracks()[0];
          if (oldAudioTrack && newAudioTrack) {
            Object.values(peerList).forEach((pc) => {
              pc.getSenders().forEach((sender) => {
                if (sender.track.kind === "audio") {
                  sender.replaceTrack(newAudioTrack);
                }
              });
            });

            oldAudioTrack.stop();
          }
            const oldVideoTrack = currentStream.getVideoTracks()[0];
            const newVideoTrack = newStream.getVideoTracks()[0];
            if (oldVideoTrack && newVideoTrack) {
                Object.values(peerList).forEach((pc) => {
                pc.getSenders().forEach((sender) => {
                    if (sender.track.kind === "video") {
                    sender.replaceTrack(newVideoTrack);
                    }
                });
                });
            }
            oldVideoTrack.stop();
        })
        .catch((e) => console.error("Failed to switch media device: ", e));
    }
    switchMediaTracks();
  }, [selectedAudio, selectedVideo]);

  function invite(peer_id) {
    if (peerList[peer_id]) {
      console.log(
        "[Not supposed to happen!] Attempting to start a connection that already exists!"
      );
    } else if (peer_id === mySid) {
      console.log("[Not supposed to happen!] Trying to connect to self!");
    } else {
      console.log(`Creating peer connection for <${peer_id}> ...`);
      createPeerConnection(peer_id);

      console.log(`Adding local_stream`);

      navigator.mediaDevices
        .getUserMedia({audio : {} , video : {width: 1080,
            height: 720,}})
        .then((stream) => {
            localStream = stream;
        })
        .catch((err) => {
            console.error("error:", err);
        });

      localStream?.getTracks()?.forEach((track) => {
        console.log("Adding track for peer_id", peer_id);
        peerList[peer_id].addTrack(track, localStream);
      });
    }
  }

  function createPeerConnection(peer_id) {
    console.log("createPeerConnection");
    peerList[peer_id] = new RTCPeerConnection(PC_CONFIG);

    peerList[peer_id].onicecandidate = (event) => {
      handleICECandidateEvent(event, peer_id);
    };
    peerList[peer_id].ontrack = (event) => {
      handleTrackEvent(event, peer_id);
    };

    peerList[peer_id].onnegotiationneeded = () => {
      handleNegotiationNeededEvent(peer_id);
    };
  }

  function handleNegotiationNeededEvent(peer_id) {
    console.log("Peer connection state: ", peerList[peer_id].connectionState);

    peerList[peer_id]
      .createOffer()
      .then((offer) => {
        return peerList[peer_id].setLocalDescription(offer);
      })
      .then(() => {
        console.log(`sending offer to <${peer_id}> ...`);
        sendViaServer({
          type: "SessionDescription", //sending offer
          payload: {
            senderId: mySid,
            targetId: peer_id,
            sdp: peerList[peer_id].localDescription.sdp,
            type: peerList[peer_id].localDescription.type,
          },
        });
      })
      .catch((e) => console.log("[ERROR] ", e));
  }

  function handleOfferMsg(msg) {
    console.log("handleOfferMsg");
    let peer_id = msg["payload"]["senderId"];
    console.log("msg", msg);

    console.log(`offer recieved from <${peer_id}>`);

    createPeerConnection(peer_id);

    let desc = new RTCSessionDescription({
      type: msg.payload.type,
      sdp: msg.payload.sdp,
    });

    peerList[peer_id]
      .setRemoteDescription(desc)
      .then(() => {
        localStream?.getTracks()?.forEach((track) => {
          console.log("adding tracks...");
          peerList[peer_id].addTrack(track, localStream);
        });
      })
      .then(() => {
        console.log("creating answer...");
        return peerList[peer_id].createAnswer();
      })
      .then((answer) => {
        console.log("setting local description...");
        return peerList[peer_id].setLocalDescription(answer);
      })
      .then(() => {
        console.log(`sending answer to <${peer_id}> ...`);
        sendViaServer({
          type: "SessionDescription", //sending answer
          payload: {
            senderId: mySid,
            targetId: peer_id,
            sdp: peerList[peer_id].localDescription.sdp,
            type: peerList[peer_id].localDescription.type,
          },
        });
      })
      .catch((e) => console.log("[ERROR] ", e));
  }

  function handleAnswerMsg(msg) {
    console.log("handleAnswerMsg");
    let peer_id = msg["payload"]["senderId"];
    console.log(`answer recieved from <${peer_id}>`);
    let desc = new RTCSessionDescription({
      type: msg.payload.type,
      sdp: msg.payload.sdp,
    });

    peerList[peer_id].setRemoteDescription(desc);
  }

  function handleICECandidateEvent(event, peer_id) {
    console.log("handleICECancidate");
    if (event.candidate) {
      sendViaServer({
        type: "IceCandidate",
        payload: {
          senderId: mySid,
          targetId: peer_id,
          candidate: event.candidate.candidate,
          sdpMLineIndex: event.candidate.sdpMLineIndex,
          sdpMid: event.candidate.sdpMid,
        },
      });
    }
  }

  function handleNewICECandidateMsg(msg) {
    console.log("handleNewICECandidateMsg msg", msg);
    var candidate = new RTCIceCandidate({
      candidate: msg.payload.candidate,
      sdpMid: msg.payload.sdpMid,
      sdpMLineIndex: msg.payload.sdpMLineIndex,
    });
    peerList[msg["payload"]["senderId"]]
      .addIceCandidate(candidate)
      .catch((e) => console.log("[ERROR] ", e));
  }

  function closeConnection(peerId) {
    console.log("closeConnection");
    if (peerId in peerList) {
      if (peerList[peerId]) {
        peerList[peerId].close();
        peerList[peerId].onicecandidate = null;
        peerList[peerId].ontrack = null;
        peerList[peerId].onnegotiationneeded = null;
      }
      delete peerList[peerId];
    }
  }

  function handleTrackEvent(event, peer_id) {
    console.log(`track event recieved from <${peer_id}>`);

    console.log("event.streams", event.streams);
    if (event.streams) {
        // todo: Find a better way to do this and to do addDiv
        console.log("Calling addTrack");
        setTimeout(() => {
            if (!_isMonitoringUser) {
                addTrack(event, peer_id);

                const participantStream = event.streams[0];

                addParticipantStream(peer_id, participantStream);
                console.log(participantStreams);
            }
        }, 1000);
    }
  }

  function start_webrtc() {
    console.log("peerList", peerList);
    console.log("mySid", mySid);
    // send offer to all other members
    for (let peerId in peerList) {
      if (peerId !== mySid) {
        invite(peerId);
        console.log("Calling invite() for " + peerId);
        addDiv(peerId);
      }
    }
  }

  function addDiv(peer_id) {
    let peerDivId = "div-id-" + peer_id;

    if (document.getElementById(peerDivId)) {
      return;
    }

    const videoDiv = document.createElement("div");
    videoDiv.classList.add(styles.videoDiv);
    videoDiv.id = peerDivId;
    videoDiv.style.width = "100%";
    videoDiv.style.height = "100%";
    videoDiv.style.borderRadius = "12px";
    videoDiv.style.overflow = "hidden";
    videoDiv.style.position = "relative";
    videoDiv.addEventListener("click", () => {
      console.log("Clicked on videoDiv");
    });

    document
      .getElementById("video-container-id")
      .appendChild(videoDiv);

    requestAnimationFrame(() => {
      videoDiv.classList.add(styles.videoDivVisible);
    });
  }

  function addTrack(event, peer_id) {
    console.log("Inside addTrack of UsbCameraMonitoringView");

    let videoElementId = "video-id-" + peer_id;
    let peerDivId = "div-id-" + peer_id;

    if (document.getElementById(videoElementId)) {
      console.log("videoElementId not found, returning");
      return;
    }

    if (!document.getElementById(peerDivId)) {
      console.log("peerDivId not found, returning");
      return;
    }

    const video = document.createElement("video");
    video.id = videoElementId;

    video.srcObject = event.streams[0];
    video.autoplay = true;
    video.style.width = "100%";
    video.style.height = "auto";
    video.style.borderRadius = "12px";

    document.getElementById(peerDivId).appendChild(video);
  }

  function removeVideoElement(peer_id) {
    let videoElementId = "video-id-" + peer_id;
    let peerDivId = "div-id-" + peer_id;

    let videoObject = document.getElementById(videoElementId);
    if (videoObject) {
      if (videoObject.srcObject) {
        videoObject.srcObject
          ?.getTracks()
          ?.forEach((track) => track.stop());
      }
      if (videoObject.hasAttribute("srcObject")) {
        videoObject.removeAttribute("srcObject");
      }
      if (videoObject.hasAttribute("src")) {
        videoObject.removeAttribute("src");
      }
      document.getElementById(videoElementId).remove();
    }
    document.getElementById(peerDivId).remove();

  }

  if (hasMonitoringEnded) {
    return <span>Monitoring ended</span>;
  } else {
    return (
      <div id="video-container-id">
        <ErrorPopUp isOpen={isErrorPopupOpen} handleClose={closePopup} />
      </div>
    );
  }
}

export default UsbCameraMonitoringView;
