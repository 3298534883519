import styles from "./style.module.scss";

export default function MuteIcon() {
  return (
    <div className={styles.MuteIcon}>
      <img
        src="/assets/icn-microphone-off.svg"
        style={{ width: "24px", height: "24px", position: "absolute", right: 16, bottom: 16 }}
      ></img>
    </div>
  );
}
