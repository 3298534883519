const Utilities = {
  createIcon: (src, alt) => {
    const icon = document.createElement("img");
    icon.src = src;
    icon.alt = alt;
    icon.style.marginRight = "12px";
    icon.style.cursor = "pointer";
    return icon;
  },

  createAndAppendIcon: (parentElement, src, alt) => {
    const icon = Utilities.createIcon(src, alt);
    parentElement.appendChild(icon);
  },
  createVerticalLine: () => {
    const verticalLine = document.createElement("div");
    verticalLine.style.width = "32px";
    verticalLine.style.height = "80%";
    verticalLine.style.borderLeft = "1px solid #EBEBF5";
    return verticalLine;
  },

  findMaxWidth: (
    parentHeight,
    parentWidth,
    boxMargin,
    aspectRatio,
    boxesCount
  ) => {
    let increment = 1;
    let maxWidth = 0;

    while (increment < 5000) {
      const area = Utilities.calculateArea(
        increment,
        parentHeight,
        parentWidth,
        boxMargin,
        aspectRatio,
        boxesCount
      );
      if (area === false) {
        maxWidth = increment - 1;
        break;
      }
      increment++;
    }
    maxWidth -= boxMargin * 2;
    return maxWidth;
  },

  calculateArea: (
    increment,
    parentHeight,
    parentWidth,
    boxMargin,
    aspectRatio,
    boxesCount
  ) => {
    let i = 0; // loop iterator
    let width = 0;
    let height = increment * aspectRatio + boxMargin * 2;
    while (i < boxesCount) {
      if (width + increment > parentWidth) {
        width = 0;
        height += increment * aspectRatio + boxMargin * 2;
      }
      width += increment + boxMargin * 2;
      i++;
    }
    if (height > parentHeight || increment > parentWidth) {
      return false;
    } else {
      return increment;
    }
  },
  getInitials: (fullName) => {
    if (typeof fullName !== "string" || fullName.trim() === "") {
      return "NA";
    }

    const words = fullName.split(" ");

    if (words.length === 1) {
     
      return words[0].slice(0, 2).toUpperCase();
    }

    const initials = words.map((word) => word[0]);
    return initials.join("").toUpperCase();
  },
};

export default Utilities;
