export const FAMILY_DATA = [
    { name: "Maurice Allen", relation: "Wife" },
    { name: "Paul Allen", relation: "Brother" },
  ];

export const CLINICIAN_DATA = {
    patientCareTeam: [
      { name: "Dr. Benson", designation: "MD" },
      { name: "RN. Daniele", designation: "Bedside" },
    ],
    clinicianDirectory: [
      { name: "Dr. Collins", designation: "MD MRH" },
      { name: "Dr. Trevor", designation: "MD Skincare" },
    ],
  };
  export const INTERPRETER_DATA = [
    { name: "Manuel Rivera", language: "Español" },
    { name: "Olivia Jones", language: "Vietnamese" },
    { name: "Diane Nguyen", language: "Español" },
    { name: "Rajesh K", language: "Hindi" },
  ];