import styles from "./style.module.scss";

export default class Button {
  static IconButton = ({ icon, onClickHandler, style, text , frequency , imageWidth , imageHeight}) => {
    return (
      <button
        style={style}
        className={styles.button}
        onClick={onClickHandler}
      >
        {icon ? <img src={icon} width={imageWidth} height={imageHeight}></img> : null }
        {text &&
          <>{text}</>
        }
        {frequency &&
           <div className={styles.frequencyContainer}>{frequency}</div>
        }
      </button>
    );
  };
}
